import { css } from '@emotion/react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React, { useMemo } from 'react'

import { ButtonLink } from '../components/common/ButtonLink'
import { SectionTitle } from '../components/common/SectionTitle'
import { FaqItems } from '../components/contents/FaqItems'
import { Hero } from '../components/contents/Hero'
// 導入事例コンテンツが作成されるまでコメントアウトで対応します
// import { InterviewLink } from '../components/contents/InterviewLink'
import { Overview } from '../components/contents/Overview'
import { Aside } from '../components/global/Aside'
import { Layout } from '../components/global/Layout'
import { Seo } from '../components/global/Seo'
import { categories } from '../data/faq.json'
import { mq, rem } from '../styles/mixin.styles'
// eslint-disable-next-line no-unused-vars
import { breakpoints, colors, fontSize, space } from '../styles/variables.styles'

type Props = {
  data: {
    allMicrocmsInterviews: GatsbyTypes.MicrocmsInterviewsConnection
  }
}

/**
 * Index
 *
 * @param data graphql.
 * @returns Interviews list contents.
 */
const IndexPage = ({ data }: Props) => {
  const faqs = useMemo(() => {
    // faqのリストから取り出す数
    const sliceNum = 4
    return categories.map((category) => {
      return {
        title: category.title,
        items: category.items.slice(0, sliceNum)
      }
    })
  }, [categories])

  return (
    <Layout type="top">
      <Seo openGraph={{ title: 'iPaS｜パレット調達支援システム' }} />
      <main>
        <Hero />
        <div css={containerStyle}>
          <Overview />
        </div>

        {/* 導入事例コンテンツが作成されるまでコメントアウトで対応します
        <div css={backgroundColorStyle}>
          <section className="background-gray">
            <div css={[containerStyle, interviewsStyle]}>
              <SectionTitle titleJa="導入事例" titleEn="Interviews" />
              <div className="interview__posts">
                {data.allMicrocmsInterviews.edges.length ? (
                  <ul className="posts">
                    {data.allMicrocmsInterviews.edges.map(
                      (edge) =>
                        edge && (
                          <li key={edge.node?.interviewsId} className="posts__item">
                            <InterviewLink edge={edge} />
                          </li>
                        )
                    )}
                  </ul>
                ) : (
                  <p>現在、公開されている導入事例はございません。</p>
                )}
              </div>
              <div className="interview__button">
                <ButtonLink link="interviews" style="primary">
                  すべての事例を見る
                </ButtonLink>
              </div>
            </div>
          </section>
        </div> */}

        <div css={backgroundColorStyle}>
          <section className="background-blue">
            <div css={[containerStyle, faqStyle]}>
              <SectionTitle titleJa="よくある質問" titleEn="FAQ" />
              <div className="faq__items">
                {faqs ? (
                  <FaqItems faqCategories={faqs} style="secondary" />
                ) : (
                  <p>現在、公開されているFAQはございません。</p>
                )}
              </div>
              <div className="faq__link">
                <ButtonLink link="faq" style="primary">
                  すべてのFAQを見る
                </ButtonLink>
              </div>
            </div>
          </section>
        </div>

        <div css={[containerStyle, companyStyle]}>
          <section className="company-section">
            <div className="company-section__media-text">
              <div className="company-section__image">
                <picture>
                  <source
                    media={`(min-width: ${breakpoints.lg}px)`}
                    width={383}
                    height={243}
                    srcSet="../images/company-building.jpg"
                  />
                  <StaticImage
                    src="../images/company-building.jpg"
                    width={540}
                    height={372}
                    alt="五十鈴グループ本社社屋全景"
                    placeholder="none"
                  />
                </picture>
              </div>
              <div className="company-section__text">
                <h2 className="company-section__title">
                  鉄鋼流通商社の五十鈴グループによる
                  <br className="is-md-only is-lg-only" />
                  長年に渡って蓄積したノウハウを
                  <br className="is-md-only is-lg-only" />
                  最大限活かして作られたシステムです。
                </h2>
                <p>
                  私たち五十鈴グループは、主に自動車や家電製品向けの素材である薄鋼板を、70年以上に渡って提供してきました。その事業の中で得た経験と蓄積してきた独自のノウハウや現場の声を集約して、パレット調達支援システムiPaSは作られました。
                </p>
              </div>
            </div>
            <h2 className="company-section__title">
              パレットを入口に自動車部品
              <br className="is-sm-only" />
              メーカー様の調達DXへ貢献します。
            </h2>
            <div className="company-section__link">
              <ButtonLink link="about" style="primary">
                私たちについて
              </ButtonLink>
            </div>
          </section>
        </div>
      </main>
      <Aside type="top" />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allMicrocmsInterviews(limit: 1, sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          category
          company
          coverImage {
            url
          }
          createdAt
          employees
          interviewsId
          name
          body {
            html
            fieldId
          }
          publishedAt(locale: "ja-JP", formatString: "YYYY/MM/DD")
        }
      }
    }
  }
`

const containerStyle = css`
  width: min(100%, ${rem(1040)});
  padding: clamp(${space.xxl}, 8vw, calc(2 * ${space.xxl})) ${space.md};
  margin-left: auto;
  margin-right: auto;
`

const backgroundColorStyle = css`
  width: 100%;
  background-color: ${colors.bgGray.hex};
  .background-blue {
    background-color: #cceeff;
  }
`

// 導入事例コンテンツが作成されるまでコメントアウトで対応します
// const interviewsStyle = css`
//   .interview__posts {
//     margin: ${rem(25)} auto 0 auto;
//     width: min(100%, ${rem(breakpoints.minContent)});
//     .posts {
//       display: flex;
//       flex-direction: column;
//       gap: ${space.lg};
//       .posts__item {
//         list-style: none;
//         border: ${rem(1)} solid ${colors.grayBorder2.hex};
//       }
//     }
//   }
//   .interview__button {
//     margin-top: clamp(${rem(30)}, 3vw, ${rem(40)});
//   }
// `

const faqStyle = css`
  .faq__items {
    margin-top: clamp(${rem(24)}, 5vw, ${rem(49)});
  }
  .faq__link {
    margin-top: clamp(${rem(30)}, 3vw, ${rem(40)});
  }
`

const companyStyle = css`
  .company-section__title {
    font-size: ${fontSize.xl};
  }
  .company-section__media-text {
    .company-section__image {
      min-width: 38.3%;
      img {
        width: 100%;
        height: auto;
      }
    }
    .company-section__title {
      grid-area: heading;
      margin-top: ${rem(30)};
    }
    p {
      grid-area: description;
      line-height: 2;
      padding-top: ${space.xs};
      padding-right: ${space.xs};
    }
    + .company-section__title {
      margin-top: clamp(${rem(20)}, 3vw, ${rem(40)});
    }
  }
  .company-section__link {
    margin-top: ${rem(40)};
  }
  ${mq('sm')} {
    .company-section__media-text {
      display: flex;
      column-gap: min(3vw, ${rem(40)});
      .company-section__image {
        img {
          height: auto;
        }
      }
      .company-section__title {
        margin-top: 0;
      }
      + .company-section__title {
        text-align: center;
        font-size: ${fontSize.xxl};
      }
    }
    h3 {
      text-align: left;
    }
  }
  ${mq('lg')} {
    .company-section__media-text {
      .company-section__image {
        img {
          width: ${rem(400)};
        }
      }
    }
  }
`
